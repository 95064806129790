module.exports = {
    Language: 'EN',
    tags: 'ტეგები',
    'tags.allTagsLink': 'ყველა თეგის დათვალიერება',
    'tags.intro': 'აქ ნახავთ ყველა თეგს',
    'tags.blog.intro': 'ბლოგის თეგები',
    'tags.pages.intro': 'გვერდის თეგები',
    'tags.nPostsTaggedWith': `{nPosts, number} {nPosts, plural,
      ერთი {post}
      სხვა {posts}
    } ტეგებით`,
    cookies:
        'ქუქი ფაილები გვეხმარება, ჩვენი სერვისების განხორციელებაში. ჩვენი სერვისების გამოყენებით, თქვენ ეთანხმებით ქუქის გამოყენებას.',
    'cookies.policy': 'ქუქის პოლიტიკა',
    'cookies.accept': 'თანხმობა',
    header: 'menu',
    'header.services': 'სერვისები',
    'header.work': 'პორტფოლიო',
    'header.blog': 'ბლოგი',
    'header.language': 'EN',
    'header.contact': 'კონტაქტი',
    subscribe: 'გამოწერა',
    'subscribe.name': 'სახელი',
    'subscribe.email': 'ელ.ფოსტა',
    'subscribe.submit': 'გაგზავნა',
    home: 'მთავარი',
    'home.hero.title': 'High-Performance Digital',
    'home.hero.subtitle':
        "We're an independent digital marketing & development agency.",
    'home.hero.cta': 'კონტაქტი',
    'home.services.title': 'სერვისები',
    'home.hire.title': 'რატომ ჩვენ?',
    'home.hire.subtitle':
        'We create possibilities that improve engagement and profitability for our customers. Every day we get on meaningful business challenges that improve your value!',
    'home.hire.cta': 'კონტაქტი',
    'home.services.subtitle':
        'Web sites should achieve business goals. Whether it is e-commerce sales, lead generation, brand development or something else, what you invest in your site should have ROI. Experience a partnership with impact-driven developers and marketers at Optimal Digital.',
    'home.works.title': 'პორტფოლიო',
    'home.works.subtitle':
        "We love what we're doing, We love digital! We collaborate with you to deliver meaningful results.",
    'home.getwork.title': "Let's get to work!",
    'home.getwork.subtitle':
        'New project on the horizon? Our digital natives are waiting you!',
    services: 'სერვისები',
    'services.roll.title': 'რას ვაკეთებთ ჩვენ',
    'services.roll.subtitle':
        'We build technologies that boosts productivity for businesses and simplifies customers’ lives.',
    'services.roll.cta': 'გაიგე მეტი →',
    'services.hire.cta': 'კონტაქტი',
    blog: 'ბლოგი',
    'blog.submit': 'გაგზავნა',
    'blog.roll.title': 'დამფუძნებლის მოგზაურობა',
    'blog.roll.subtitle':
        'წაიკითხეთ რეალური გამოცდილება, სირთულეები თუ როგორ გამოიყურება წარმატებისკენ მიმავალი გზა Optimal Digital – ის გუნდისგან.',
    'blog.keep.reading': 'განაგრძე კითხვა →',
    'blog.time.read': 'წუთი წასაკითხად',
    'blog.post.view': 'ნახვა',
    'blog.post.newsletter.first': 'მოგეწონა ბლოგი? გახდი ჩვენი გამომწერი.',
    'blog.post.newsletter.second':
        'მზად ხარ მოგზაურობისთვის? გახდი ჩვენი გამომწერი.',
    contact: 'კონტაქტი',
    'contact.title': 'გაქვთ იდეა?',
    'contact.name': 'სახელი',
    'contact.email': 'ელ.ფოსტა',
    'contact.message': 'ტექსტი',
    'contact.send': 'გაგზავნა',
    not_found: '404 - გვერდი არ მოიძებნა',
    404: 'თქვენ მოხვდით უმისამართ გვერდზე...',
    work: 'Work',
    'work.roll.title': 'რაც გავაკეთეთ',
    'work.roll.subtitle':
        'ჩვენ ვქმნით ციფრული გადაწყვეტის გზებს, რომელიც ზრდის პროდუქტიულობას ბიზნესისთვის და ამარტივებს მომხმარებლის ცხოვრებას.',
    'work.case.cta': 'ვრცლად →',
    'work.hire.arrow.cta': 'კონტაქტი →',
    'work.post.location': 'ადგილმდებარეობა',
    'work.post.date': 'თარიღი',
    'work.post.timeline': 'ვადები',
    'work.post.team': 'გუნდი',
    'work.post.features': 'მახასიათებლები',
    'work.post.services': 'სერვისები',
    'work.post.tools': 'ტექნოლოგიები',
    'work.post.hireus': 'კონტაქტი',
    footer: 'Footer',
    'footer.title.services': 'მომსახურებები',
    'footer.title.company': 'კომპანია',
    'footer.title.links': 'ბმულები',
    'footer.title.follow': 'მოგვყევი',
    'footer.link.privacy': 'კონფიდენციალურობის პოლიტიკა',
    'footer.link.cookie': 'ქუქის პოლიტიკა',
    'footer.link.technologies': 'ტექნოლოგიები',
    'footer.link.blog': 'ბლოგი',
    'footer.link.services': 'სერვისები',
    'footer.link.work': 'პორტფოლიო',
    'footer.link.blockchain-development': 'ბლოქჩეინის დამზადება',
    'footer.link.web-development': 'საიტის დამზადება',
    'footer.link.app-development': 'აპლიკაციის დამზადება',
    'footer.link.digital-marketing': 'ციფრული მარკეტინგი',
    'footer.link.IoT': 'ნივთების ინტერნეტი',
    'footer.link.submit': 'გაგზავნა',
    copyright: '© 2020 ოპტიმალ დიჯითალ. შექმნილია ♥︎ თბილისიდან.',
}
