import React from 'react'
import Logo from '../../img/logo.png'
import SelectLanguage from '../SelectLanguage'
import { FormattedMessage } from 'react-intl'
import { AnchorLink } from 'gatsby-plugin-anchor-links'
import { Link, animateScroll as scroll } from 'react-scroll'

const Header = class extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            active: false,
            navBarActiveClass: '',
        }
    }

    toggleHamburger = () => {
        // toggle the active boolean in the state
        this.setState(
            {
                active: !this.state.active,
            },
            // after state has been updated,
            () => {
                // set the class in state for the navbar accordingly
                this.state.active
                    ? this.setState({
                          navBarActiveClass: 'is-active',
                      })
                    : this.setState({
                          navBarActiveClass: '',
                      })
            }
        )
    }

    render() {
        const props = this.props
        const lang = `${props.langKey !== 'en' ? props.langKey : ''}`
        return (
            <nav
                className="navbar is-fixed-top"
                role="navigation"
                aria-label="main-navigation"
            >
                <div className="container">
                    <div className="navbar-brand">
                        <AnchorLink className="navbar-logo" to={'/' + lang}>
                            <img src={Logo} alt="Startup Grind Georgia" />
                        </AnchorLink>
                        <div
                            className={`navbar-burger burger ${this.state.navBarActiveClass}`}
                            data-target="navMenu"
                            onClick={() => this.toggleHamburger()}
                        >
                            <span className="navbar-burger-span" />
                            <span className="navbar-burger-span" />
                            <span className="navbar-burger-span" />
                        </div>
                    </div>
                    <div
                        id="navMenu"
                        className={`navbar-menu ${this.state.navBarActiveClass}`}
                    >
                        <div className="navbar-start">
                            <a
                                activeClass="navbar-item-active"
                                className="navbar-item"
                                // to="home"
                                // spy={true}
                                // smooth={true}
                                // offset={-70}
                                // duration={500}
                                href="https://startupgrind.ge"
                            >
                                <FormattedMessage id="header.home" />
                            </a>
                            <a
                                activeClass="navbar-item-active"
                                className="navbar-item"
                                // to="about"
                                // spy={true}
                                // smooth={true}
                                // offset={-70}
                                // duration={500}
                                href="https://startupgrind.ge#about"
                            >
                                <FormattedMessage id="header.about" />
                            </a>
                            <a
                                activeClass="navbar-item-active"
                                className="navbar-item"
                                // to="speakers"
                                // spy={true}
                                // smooth={true}
                                // offset={-70}
                                // duration={500}
                                href="https://startupgrind.ge#speakers"
                            >
                                <FormattedMessage id="header.speakers" />
                            </a>

                            <a
                                activeClass="navbar-item-active"
                                className="navbar-item"
                                // to="sponsors"
                                // spy={true}
                                // smooth={true}
                                // offset={-70}
                                // duration={500}
                                href="https://startupgrind.ge#sponsors"
                            >
                                <FormattedMessage id="header.sponsors" />
                            </a>

                            <a
                                activeClass="navbar-item-active"
                                className="navbar-item"
                                href="https://startupgrind.ge#media"
                            >
                                <FormattedMessage id="header.media" />
                            </a>

                            <a
                                activeClass="navbar-item-active"
                                className="navbar-item"
                                target="_blank"
                                href="https://startupgrind.ge/2020showcase/"
                            >
                                <FormattedMessage id="header.competition" />
                            </a>
                        </div>

                        <div className="navbar-end">
                            <a
                                className="navbar-button"
                                href="https://www.startupgrind.com/events/details/startup-grind-tbilisi-presents-startup-grind-europe-asia-connect-regional-conference/"
                                target="_blank"
                            >
                                <button
                                    id="getticket"
                                    className="button is-medium is-primary has-text-weight-bold"
                                >
                                    <FormattedMessage id="home.hero.cta" />
                                </button>
                            </a>
                            {/* <SelectLanguage langs={props.langs} /> */}
                        </div>
                    </div>
                </div>
            </nav>
        )
    }
}

export default Header
