module.exports = {
    Language: 'EN',
    tags: 'Tags',
    'tags.allTagsLink': 'Browse all tags',
    'tags.intro': 'Here you can find all the tags',
    'tags.blog.intro': 'Here you can find all the blog tags',
    'tags.pages.intro': 'Here you can find all the tags in the pages',
    'tags.nPostsTaggedWith': `{nPosts, number} {nPosts, plural,
      one {post}
      other {posts}
    } tagged with`,
    cookies:
        'Cookies help us deliver our services. By using our services, you agree to our use of cookies.',
    'cookies.policy': 'Cookies Policy',
    'cookies.accept': 'Accept',
    header: 'menu',
    'header.home': 'Home',
    'header.about': 'About',
    'header.speakers': 'Speakers',
    'header.competition': 'Competition',
    'header.sponsors': 'Sponsors',
    'header.media': 'Media',
    'header.language': 'EN',
    subscribe: 'Subscribe',
    'subscribe.name': 'Full Name',
    'subscribe.email': 'Email',
    'subscribe.submit': 'Submit',
    home: 'Home',
    'home.hero.title': 'Startup Grind Europe-Asia Connect',
    'home.hero.subtitle':
        'an online conference that brings startups and investors together',
    'home.hero.cta': 'Get Tickets',
    'home.what.title': 'What You Get',
    'home.what.pricing': 'Best pricing',
    'home.what.workload': 'Reduce workload',
    'home.what.tracking': 'Real time',
    'home.unify.title': 'One space for unify your company supply chain',
    'home.unify.subtitle':
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras varius purus at tincidunt ullamcorper. Sed sit amet velit non dui tempor maximus eu sit amet quam. Suspendisse odio tortor, interdum vel nunc nec, malesuada egestas felis.',
    'home.unify.cta': 'Learn More',
    'home.services.title': 'All options for your transportation requirements',
    'home.hire.title': 'Why you hire us?',
    'home.hire.subtitle':
        'We create possibilities that improve engagement and profitability for our customers. Every day we get on meaningful business challenges that improve your value!',
    'home.hire.cta': 'Contact',
    'home.services.subtitle':
        'Web sites should achieve business goals. Whether it is e-commerce sales, lead generation, brand development or something else, what you invest in your site should have ROI. Experience a partnership with impact-driven developers and marketers at Optimal Digital.',
    'home.works.title': 'Featured works',
    'home.works.subtitle':
        "We love what we're doing, We love digital! We collaborate with you to deliver meaningful results.",
    'home.media.title': 'Media',
    'home.getwork.subtitle':
        'New project on the horizon? Our digital natives are waiting you!',
    services: 'Services',
    'services.roll.title': 'What we do',
    'services.roll.subtitle':
        'We build technologies that boosts productivity for businesses and simplifies customers’ lives.',
    'services.roll.cta': 'Learn More →',
    'services.hire.cta': 'Hire Us',
    blog: 'Blog',
    'blog.submit': 'Submit',
    'blog.roll.title': 'Founder Journey',
    'blog.roll.subtitle':
        'Read real experiences, challenges, and what the road to success looks like from the team behind Optimal Digital.',
    'blog.roll.subscribe.placeholder': 'Enter your email to subscribe',
    'blog-message': 'Last News',
    'blog.keep.reading': 'Keep reading →',
    'blog.time.read': 'min read',
    'blog.post.view': 'views',
    'blog.post.newsletter.first': 'Like What You Read? Join our subrcribers.',
    'blog.post.newsletter.second': 'Ready for more? Join our subrcribers.',
    'find-out-more': 'Find out more...',
    contact: 'Contact',
    'contact.title': "Let's Talk",
    'contact.name': 'Name',
    'contact.email': 'Email',
    'contact.message': 'Message',
    'contact.send': 'Send',
    not_found: '404 - Page Not Found',
    404: 'You are arrived to an unvailable page or you have missed the path...',
    work: 'Work',
    'work.roll.title': 'What we done',
    'work.roll.subtitle':
        'We build software that boosts productivity for businesses and simplifies customers’ lives.',
    'work.case.cta': 'View Case Study →',
    'work.hire.arrow.cta': 'Hire Us →',
    'work.post.location': 'Location',
    'work.post.date': 'Date',
    'work.post.timeline': 'Timeline',
    'work.post.team': 'Team',
    'work.post.features': 'Features',
    'work.post.services': 'Services',
    'work.post.tools': 'Tools',
    'work.post.hireus': 'Hire Us',
    footer: 'Footer',
    'footer.title.services': 'Services',
    'footer.title.company': 'Company',
    'footer.title.links': 'Links',
    'footer.title.updates': 'Learn More',
    'footer.link.privacy': 'Privacy policy',
    'footer.link.cookie': 'Cookie policy',
    'footer.link.contact': 'Contact Us',
    'footer.link.more': 'More',
    copyright: '© 2020 Startup Grind Georgia. Made with ♥︎ from Tbilisi.',
}
