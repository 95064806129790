import React, { Component } from 'react'
import FaChevronUp from '@meronex/icons/fa/FaChevronUp'

class ScrollToTop extends Component {
    componentDidMount() {
        // Get all "#toTop" elements
        const _toTop = Array.prototype.slice.call(
            document.querySelectorAll('#toTop'),
            0
        )
        // Check if there are any navbar links
        if (_toTop.length > 0) {
            // Add a click event on each of them
            _toTop.forEach((el) => {
                el.addEventListener('click', () => {
                    window.scrollTo({ top: 0, behavior: 'smooth' })
                })
            })
        }
    }

    render() {
        return (
            <button
                className="button is-outlined is-primary"
                id="toTop"
                title="Top"
            >
                <FaChevronUp />
            </button>
        )
    }
}

export default ScrollToTop
