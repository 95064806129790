import React from 'react'
import { Link } from 'gatsby'
import { FormattedMessage, injectIntl } from 'react-intl'
import menuTree from '../../data/menuTree'
import select from '../utils'
import FaLinkedin from '@meronex/icons/fa/FaLinkedin'
import FaFacebookSquare from '@meronex/icons/fa/FaFacebookSquare'
import FaTwitterSquare from '@meronex/icons/fa/FaTwitterSquare'
import FaInstagramSquare from '@meronex/icons/fa/FaInstagramSquare'
import FaYoutubeSquare from '@meronex/icons/fa/FaYoutubeSquare'
import ScrollToTop from '../ScrollToTop'
import FooterNewsletterForm from '../NewsletterForm/footer-subscribe'
import Logo from '../../img/logo.png'
import Modal from '../Modal'
import UseModal from '../UseModal'
import ContactUs from '../ContactUs'

function ContactModal() {
    const [isShowing, toggle] = UseModal()
    return (
        <div>
            <a
                className="has-text-footer-link-color is-hover-active"
                onClick={toggle}
            >
                <FormattedMessage id="footer.link.contact" />
            </a>
            <Modal isShowing={isShowing} toggle={toggle}>
                <ContactUs />
            </Modal>
        </div>
    )
}
class Footer extends React.Component {
    render() {
        const props = this.props
        const sel = select(props.langKey)
        const lang = `${props.langKey !== 'en' ? props.langKey : ''}`
        return (
            <footer className="footer is-font-secondary has-background-white">
                <div className="container">
                    <div className="columns is-vcentered is-mobile">
                        <div className="column is-6">
                            <div className="footer-logo">
                                <img src={Logo} alt="OMOFOX" />
                            </div>
                        </div>
                        <div className="column is-6">
                            <div className="is-right-scroll is-flex">
                                <ScrollToTop />
                            </div>
                        </div>
                    </div>
                    <div className="mt-6 columns is-multiline">
                        <div className="column is-3">
                            <p className="has-text-weight-bold has-text-footer-header-color">
                                <FormattedMessage id="footer.title.links" />
                            </p>
                            <ul>
                                <li className="mt-3">
                                    <Link
                                        className="has-text-footer-link-color is-hover-active"
                                        to={
                                            lang +
                                            '/' +
                                            menuTree.cookie[sel] +
                                            '/'
                                        }
                                    >
                                        <FormattedMessage id="footer.link.cookie" />
                                    </Link>
                                </li>
                                <li className="has-padding-top">
                                    <Link
                                        className="has-text-footer-link-color is-hover-active"
                                        to={
                                            lang +
                                            '/' +
                                            menuTree.privacy[sel] +
                                            '/'
                                        }
                                    >
                                        <FormattedMessage id="footer.link.privacy" />
                                    </Link>
                                </li>
                            </ul>
                        </div>
                        <div className="column is-3">
                            <p className="has-text-weight-bold has-text-footer-header-color">
                                <FormattedMessage id="footer.title.links" />
                            </p>
                            <ul>
                                <li className="mt-3">
                                    <a
                                        href="https://share.hsforms.com/16bC9IPnWTECQljtT5qN-vg4u214"
                                        target="_blank"
                                    >
                                        <FormattedMessage id="contact" />
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div className="column is-offset-1 is-5">
                            <p className="has-text-weight-bold has-text-footer-header-color">
                                <FormattedMessage id="footer.title.updates" />
                            </p>
                            <FooterNewsletterForm />
                            <div className="mt-4">
                                <a
                                    title="facebook"
                                    target="_blank"
                                    href="https://www.facebook.com/startupgrindtbilisi/"
                                >
                                    <FaFacebookSquare
                                        className="mr-2 is-facebook-icon"
                                        size="2.5rem"
                                    />
                                </a>{' '}
                                <a
                                    title="instagram"
                                    target="_blank"
                                    href="https://www.instagram.com/startupgrindtbilisigeorgia/"
                                >
                                    <FaInstagramSquare
                                        className="mr-2 is-instagram-icon"
                                        size="2.5rem"
                                    />
                                </a>
                                <a
                                    title="twitter"
                                    target="_blank"
                                    href="https://twitter.com/GrindGeorgia"
                                >
                                    <FaTwitterSquare
                                        className="mx-2 is-twitter-icon"
                                        size="2.5rem"
                                    />
                                </a>
                                <a
                                    title="youtube"
                                    target="_blank"
                                    href="https://www.youtube.com/channel/UCn8weC13LUHw_YPMaa8naig"
                                >
                                    <FaYoutubeSquare
                                        className="mx-2 is-youtube-icon"
                                        size="2.5rem"
                                    />
                                </a>
                                <a
                                    title="linkedin"
                                    target="_blank"
                                    href="https://www.linkedin.com/in/startup-grind-tbilisi-b3b56212a/"
                                >
                                    <FaLinkedin
                                        className="mx-2 is-linkedin-icon"
                                        size="2.5rem"
                                    />
                                </a>
                            </div>
                        </div>
                        <div className="column is-6 has-text-left">
                            <div className="is-size-7 has-text-footer-header-color">
                                <FormattedMessage id="copyright" />
                            </div>
                        </div>{' '}
                        <div className="column is-6 has-text-right">
                            <div className="is-size-7 has-text-footer-header-color">
                                Developed by Optimal Digital
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        )
    }
}

export default injectIntl(Footer)
